/* eslint-disable import/no-anonymous-default-export */
export default {

    tr: {
        translation: {
            language: 'TR',

            reservationOperations: "Ziyaret İşlemleri",
            mainMenu: "Ana Menü",
            userMenu: "Kullanıcı Menüsü",
            userEdit: "Kullanıcı Düzenle",
            userRole: "Kullanıcı Rolü",

            securityMenu: "Güvenlik Görevlisi Menüsü",
            securityMenu2: "Güvenlik Menüsü",
            userList: "Kullanıcı Listesi",

            userProcedures: "Kullanıcı İşlemleri",
            reporting: "Raporlama",
            adminPanel: "Yönetim Paneli",
            authorizedUserMenu: "Yetkili Kullanıcı Menüsü",
            serviceCars: "Servis Araçları Takibi",
            createServiceCarsDaily: "Günlük Takip Listesi Oluştur",
            companyCarTracking: "Şirket Aracı Takibi",
            visitReservation: 'Ziyaret Randevusu',
            freightLoadingReservation: 'Yük Sevkiyat Randevusu',
            freightArrivalReservation: 'Malzeme Kabul Randevusu',
            myUserProcedures: 'Kullanıcı İşlemlerim',
            companyCarTracing: 'Şirket Aracı Takibi',
            freightProcedures: 'Yük Aracı İşlemleri',
            cardlessPersonnelProcedures: 'Kartsız Personel İşlemleri',
            cardlessPersonnelCreate: 'Kartsız Personel Ekleme',
            visitProcedures: 'Ziyaret İşlemleri',
            serviceCarsProcedures: 'Servis Araçları İşlemleri',
            companyCarsProcedures: 'Şirket Aracı İşlemleri',
            reservationApproval: 'Randevu Onay',
            companyCarEditTitle: 'Araç Takibi Düzenle',
            weeklyVisitTracking: 'Haftalık Ziyaret Takibi',
            weeklyVisits: 'Haftalık Ziyaretler',
            operationSettings: 'Operasyon Ayarları',

            dailyVisitTracking: 'Günlük Ziyaret Takibi',
            dailyVisits: 'Günlük Ziyaretler',

            // COMPANY CARS TRACKING PAGE
            companyCarTrackingTitle: 'Şirket Aracı Takibi',
            operation: 'İşlem',
            details: 'Detay',
            trackingDate: 'Tarih',
            fullName: 'Ad Soyad',
            cardNumber: 'Kart Numarası',



            firmVisits: 'Şirket Ziyaretleri',
            phone: "Telefon Numarası",
            description: 'Açıklama',
            create: 'Oluştur',
            time: 'Saat',
            companyName: 'Şirket İsmi',
            companyEmail: 'Şirket E-mail',
            commonEmail: 'E-posta',
            commonUserRole: 'Kulanıcı Rolü',
            companyWorkFor: 'Çalıştığı Şirket',


            //COMMON !! TEXT FOR BUTTONS, COLUMNS & INFO TEXT
            commonEdit: "Düzenle",
            commonDelete: "Sil",
            commonEditUser: "Kullanıcı Düzenle",
            commonEditRes: "Rezervasyon Düzenle",
            commonDescription: "Açıklama",
            commonSaveButton: 'Kaydet',
            commonCancelButton: 'Vazgeç',
            commonRemoveButton: 'Çıkar',
            commonCreateButton: 'Oluştur',
            commonAddButton: 'Ekle',
            commonDate: "Tarih",
            commonEntryDate: "Giriş Tarihi",
            commonExitDate: "Çıkış Tarihi",

            commonWhoToMeet: 'Ziyaret Edilecek Kişi',
            commonWhosMeeting: 'Ziyaret Edecek Kişi',
            commonOkButton: "Tamam",
            commonHideButton: "Gizle",
            commonDecisionRes: "Randevu Onayla/İptal",
            commonShowDescription: "Açıklamayı gör",
            commonAccept: "Onayla",
            commonReject: "Reddet",

            //Login Page
            logo1: "Ware",
            logo2: "Manage",
            title: "Renault Ware Manage Sistemi ile Tanışın.",
            text1: "Yeni nesil kullanışlı arayüzü ile malzeme kabul-yükleme veya şahsi ziyaretleriniz için rezervasyonda bulunabilirsiniz. Ware Manage ile tanışın.",
            emai: "E-mail adresiniz",
            password: "Şifre",
            rememberMe: "Beni Hatırla",
            loginAsGuest: "Misafir Olarak Devam Et",
            login: "Giriş Yap",
            email: "E-Posta Adresi",
            newPassword: "Şifre Yenile",
            welcomeText: "Ware Manage Randevu Sistemine Hoşgeldiniz",

            //NO PAGE 
            noPage1: "Bu sayfayı görüntülemeye yetkiniz yok.",
            noPage2: "Sayfayı görüntülemeniz gerektiğini düşünüyorsanız lütfen yetkili biriyle görüşünüz.",


            // Register Page
            register: "Kayıt Ol",
            company: "Şirket",
            registrationSucceeded: "Kayıt Başarılı",
            successRegisterMessage: "Kaydınız başarıyla alınmıştır. Kaydınız onaylandıktan sonra e-mail adresinize bilgilendirme maili gönderilecektir.",
            registrationFailed: "Kayıt Başarısız",
            failedRegisterMessage: "Kaydınız alınamadı. Kayıt olurken girdiğiniz bilgileri kontrol ediniz.",
            errorPasswordLengthMessage: "Şifreniz en az 6 karakterden oluşmalıdır.",
            companySelectionNote: "Şirket adınız listede yoksa, aramak için yazmaya başlayın.",

            //User Menu Page
            unloadRes: "Malzeme Kabul Randevusu",
            loadRes: "Yük Sevkiyat Randevusu",
            visitRes: "Ziyaret Randevusu",
            userOperations: "Kullanıcı İşlemlerim",
            passwordsNotMatch: "Şifreler Uyuşmuyor",
            passwordLength: "Şifre En Az 6 Karakter Olmalıdır",

            //User Settings
            reservationEdit: "Randevu Düzenleme/Silme",
            reservationApprove: "Randevu Onay",
            userSettings: "Kullanıcı Ayarları",
            createNewUser: "Yeni Kullanıcı Oluştur",
            createNewUserTitle: "Yeni Kullanıcı Oluşturma",
            createNewFirm: "Yeni Şirket Oluştur",
            companyList: "Şirket Listesi",
            editCompany: "Şirket Düzenle",

            // User Approval 
            userApproval: "Kullanıcı Onayla/Reddet",


            //VisitingOperations
            createVisit: "Ziyaret Oluşturma",

            //Cardless Employee
            titleCardless: "Kartsız Personel Takibi Ekleme",
            dateCardless: "Tarih",
            whoToVisit: "Ziyaret Edecek Kişi",
            securityEmployee: "Güvenlik Görevlisi",
            visitInOut: "Ziyaret Giriş-Çıkış Kaydetme",
            editCardlessEmployee: "Kartsız Personel Düzenle",
            cardlessEmployees: "Kartsız Personel",

            //Analytics Page
            today: "Bugünün Ziyaretleri",
            week: "Haftanın Ziyaretleri",
            firm: "Şirketnın Ziyaretleri",

            //Authorized User Reservation Page
            authInputField1: "İsim Soyisim",
            phoneNumberLabel: "Telefon Numarası",
            authInputField3: "Görüşülecek Kişi",
            authInputField4: "Görüşme Sebebi",
            authInputField5: "Açıklamalar",
            authInputField6: 'Görüşülecek gün',
            authInputField7: 'Görüşülecek saat',
            plateLabel: "Plaka",
            truckPlateLabel: 'Dorse Plakası',

            arrivalTimeLabel: 'Varış Saati',
            exitTimeLabel: 'Çıkış Saati',

            // Guest User Reservation Page
            firstName: 'Ad',
            firstName2: 'İsim',
            lastName: 'Soyad',
            fullNameOfThePersonToMeet: 'Görüşülecek Kişinin Tam Adı',
            passwordRepeat: 'Şifre Tekrar',
            enlightmentText: "Aydınlatma Metni'ni okudum ve onaylıyorum",

            //Security Menu
            loadTruckOperations: "Yük Aracı İşlemleri",
            cardlessEmployeeOperations: "Kartsız Personel İşlemleri",
            visitOperations: "Ziyaret İşlemleri",
            usersOperations: "Kullanıcı İşlemleri",
            serviceCarsOperations: "Servis Aracı Takibi",
            companyCarsOperations: "Şirket Aracı Takibi",

            //Freight Handling Page
            bookANewFreightAppointment: "Yeni Yük Aracı Randevusu",
            // freightAppointments: "Randevuları Görüntüle",
            loadTruck: "Yük Sevkiyat İşlemleri",
            unloadTruck: "Malzeme Kabul İşlemleri",
            companyWhichBook: "Randevu Alan Şirket",
            meetingPoint: 'Görüşülecek Kısım',
            reservationDay: 'Ziyaret Günü',
            gateNumber: 'Kapı (Gate)',
            peron: 'Peron',
            referenceNumber: 'Referans (OT) Numarası',
            reservationTime: 'Ziyaret Saati',
            freightVehicleEdit: 'Yük Aracı Düzenle',
            vehiclePlate: 'Araç Plakası',
            reservationDate: 'Ziyaret Tarihi',
            freightVehicleLoad: 'Yük Aracı Sevkiyat Kaydetme',
            freightVehicleLoadTitle: 'Yük Aracı Sevkiyat',
            towTruckPlate: 'Araç/Çekici Plakası',
            loadReservationNote: 'Alacağınız randevu ilk stop noktası içindir.',
            loadReservationWarning: 'Rampa yükleme standartlarına uymayan araçlar kabul edilmeyecektir.',

            freightVehicleUnload: 'Yük Aracı Kabul Kaydetme',
            freightVehicleUnloadTitle: 'Yük Aracı Kabul',
            loadReservationApproval: 'Randevu Iptal/Onay',
            freightCalendar: 'Operasyon Takvimi',
            freightHandlingMenu: 'Yük Aracı (Operasyon) İşlemleri',
            assignGateAndRoute: 'Rampa ve Rota Ata',
            route2: 'Rota',
            gateNumber2: 'Rampa',
            plate: 'Plaka',
            noWaitingAppointments: 'Bekleyen randevu bulunmamaktadır.',

            rejectFreightAppointment: 'Randevuyu Reddet',
            rejectFreightAppointmentText: 'Randevuyu reddetmek istediğinize emin misiniz?',
            reject: 'Reddet',
            approve: 'Onayla',

            visitCalendar: 'Ziyaret Takvimi',
            workingHoursManagement: 'Çalışma Saatleri Ayarları',

            // freight calendar
            monday: 'Pazartesi',
            tuesday: 'Salı',
            wednesday: 'Çarşamba',
            thursday: 'Perşembe',
            friday: 'Cuma',
            saturday: 'Cumartesi',
            seeCalendar: 'Takvimi Görüntüle',
            freightArea: 'Operasyon Alanı',
            appointmentDetails: 'Randevu Detayları',

            //freight areas
            1010: '1010',
            3020: '3020',
            MAIS_SEVKIYAT: 'MAİS SEVKİYAT',
            ANTREPO: 'ANTREPO',
            GPKS: 'GARANTİLİ PARÇALAR KABUL SERVİSİ',
            OTHER: 'DİĞER',

            //Sub Titles
            editingReservation: 'Randevu Düzenleme',
            editReservation: 'Randevuyu Düzenle',

            //Create Firm Page
            createFirm: 'Şirket Oluştur',
            createFirmForm: 'Şirket Oluşturma Formu',
            saveFirm: 'Şirket Kaydet',

            //Create User Page
            createUser: 'Kullanıcı Oluştur',
            createUserForm: 'Kullanıcı Oluşturma Formu',

            //Service Cars Tracking Page
            addNewRoute: 'Yeni Güzergah Ekleyin',
            route: 'Güzergah',

            removeOldRoute: 'Güzergah Çıkarın',
            addCarTracking: 'Araç Takibi Ekleyin',
            routeName: 'Güzergah Adı',
            entranceTime: 'Giriş Saati',
            exitTime: 'Çıkış Saati',
            entranceCarPlate: 'Giriş Araç Plakası',
            exitCarPlate: 'Çıkış Araç Plakası',
            addNewCarTracking: 'Araç Takibi Ekleyin',
            serviceCarAdd: 'Servis Aracı Ekleme',
            morningEntryTime: 'Sabah Giriş Saati',
            eveningExitTime: 'Akşam Çıkış Saati',
            eveningEntryTime: 'Akşam Giriş Saati',


            //Buttons
            addRoute: 'Güzergah Ekle',
            deleteRoute: 'Güzergah Çıkar',
            addTrack: 'Araç Takibi Ekle',

            //Reservation Edit Page
            reservationList: 'Randevu Listesi',
            timeErrorMessage: 'Lütfen girilen saatleri kontrol ediniz.',

            //Company Car Arrival
            carArrivalTitle: 'Araç Takibi Ekleme',
            carArrivalDate: 'Planlanan Tarih',
            carArrivalDateIn: 'Planlanan Giriş',
            carArrivalDateOut: 'Planlanan  Çıkış',
            carArrivalTableTitle: "Şirket Aracı Takibi",
            companyCarAdd: "Şirket Aracı Ekle",

            //NoPage
            missingPage: 'Aradığınız Sayfa Bulunamadı.',

            //Reporting Menu
            visitAppointments: 'Ziyaret (Bireysel) Randevuları',
            personToMeet: 'Görüşülecek Kişi',
            appointmentWith: 'Görüşülecek Kişi',
            freightAppointments: 'Yük (Operasyon) Randevuları',
            visitorFullName: 'Ziyaretçi',
            // other: 'Diğer',

            //Daily Visit Tracking
            dailyVisitTitle: "Ziyaret Varış-Çıkış Ekleme",
            dailyTracking: "Günlük Ziyaret Takibi",
            visitInOutDaily: "Ziyaret Giriş-Çıkış",

            // APPOINTMENTS PAGE
            appointments: "Randevular",
            appointmentDate: "Randevu Tarihi",
            appointmentTime: "Randevu Saati",
            appointmentType: "Randevu Tipi",
            appointmentStatus: "Randevu Durumu",
            appointmentReason: "Görüşme Sebebi",
            estimatedExitTime: "Tahmini Çıkış Saati",
            estimatedEntryTime: "Tahmini Giriş Saati",
            arrivalTime: 'Varış Saati',
            actualExitTime: 'Gerçekleşen Çıkış Saati',

            //APPOINTMENT TYPES
            VISIT: "ZİYARET",
            OPERATION: "OPERASYON",
            OTHER: "DİĞER",

            //APPOINTMENT STATUSES
            PENDING: "ONAY BEKLİYOR",
            APPROVED: "ONAYLANDI",
            IN_PROGRESS: 'İÇERDE',
            REJECTED: "REDDEDİLDİ",
            CANCELLED: "İPTAL EDİLDİ",
            COMPLETED: "TAMAMLANDI",
            PARTNER_COMPANY_PENDING: "ŞİRKET YETKİLİSİ ONAY BEKLİYOR",
            PARTNER_COMPANY_APPROVED: "ŞİRKET YETKİLİSİ ONAYLANDI",

            //ROLES
            SUPER_ADMIN: 'SÜPER ADMİN',
            AUTHORIZED_USER: 'YETKİLİ KULLANICI',
            PARTNER_COMPANY_AUTHORIZED_USER: 'PARTNER FİRMA YETKİLİ KULLANICI',
            OPERATION_RESPONSIBLE: 'OPERASYON SORUMLUSU',
            SECURITY_GUARD: 'GÜVENLİK GÖREVLİSİ',
            STANDARD_USER: 'STANDART KULLANICI',
            GUEST: 'MİSAFİR',

            // Guest Reservation
            guestReservation: "Misafir Randevusu",

            //pop-up mesajları
            errorMessageTitle: "Kaydınız alınamadı.",
            errorMessage: "Lütfen tüm alanların doldurulduğuna emin olunuz.",
            errorPasswordMessage: "Şifre ve Şifre Tekrarı uyuşmamaktadır.",
            deleteTitle: "Kaydı silmek istediğinize emin misiniz?",
            deleteText: "Kaydı silmek için devam edin",
            //snackbar mesajları
            successMessage: "Kaydınız başarıyla alınmıştır.",
            successMessageForDelete: "Kaydınız başarıyla silinmiştir.",
            successAppointmentUpdate: "Randevu başarıyla güncellenmiştir.",

            // Forgot password page
            send: "Gönder",
            resetPassword: "Şifre Yenile",
            'popup.invalidToken.title': 'Geçersiz Link',
            'popup.invalidToken.text': 'Şifre sıfırlama linkiniz geçersizdir. Lütfen tekrar şifre sıfırlama talebinde bulunun.',
            'popup.invalidToken.button': 'Tamam',


            // SIDE BAR
            mainMenu: 'Ana Menü',
            userManagement: 'Kullanıcı Yönetimi',
            visitingOperations: 'Ziyaret İşlemleri',
            freightOperations: 'Yük (Operasyon) İşlemleri',
            logout: 'Çıkış Yap',

            // VERBS
            edit: 'Düzenle',
            delete: 'Sil',

            viewAppointments: 'Randevularımı Görüntüle',
            appointmentBoard: 'Randevu Panosu',
            operationResponsibleList: 'Operasyon Sorumluları',
            operationResponsible: 'Operasyon Sorumlusu',
            addOperationResponsible: 'Operasyon Sorumlusu Ekle',

            // freight download
            downloadAllCsv: 'CSV indir',
            'company.name': 'Şirket Adı',
            // 'appointmentDate',
            // 'appointmentTime',

            // freight fields
            'freight.freightArea': 'Operasyon Alanı',
            'freight.plate': 'Araç Plakası',
            'freight.trailerNumber': 'Dorse Plakası',

            // created by 
            'createdBy.email': 'Randevu Oluşturan E-mail',

            // entrance and exit times 
            // 'entranceTime': 'Giriş Saati',
            // 'actualExitTime': 'Çıkış Saati',


            // Working Hours Management Page 
            startTime: 'Başlangıç Saati',
            endTime: 'Bitiş Saati',
            slotDuration: 'Randevu Süresi (Dakika)',
            slotDurationFor3020Sevkiyat: 'Randevu Süresi (3020 Sevkiyat)',
            breaks: 'Molalar',
            addBreak: 'Mola Ekle',
            breakStart: 'Mola Başlangıç',
            breakEnd: 'Mola Bitiş',
            extraHours: 'Mesai Saatleri',
            enableExtraHours: 'Mesai Saatlerini Etkinleştir',
            disableExtraHours: 'Mesai Saatlerini Devre Dışı Bırak',
            saveConfiguration: 'Ayarları Kaydet',
            saveConfigurationText: 'Yaptığınız değişikler randevu alım saatlerine etki edecektir. Devam etmek istiyor musunuz?',
        }
    },
    en: {
        translation: {
            language: 'EN',

            reservationOperations: 'Visit Operations',
            userMenu: 'User Menu',
            userEdit: 'Edit User',
            userRole: 'User Role',

            securityMenu: 'Security Officer Menu',
            securityMenu2: 'Security Menu',
            userList: 'User List',

            userProcedures: 'User Procedures',
            reporting: 'Reporting',
            adminPanel: 'Admin Panel',
            authorizedUserMenu: 'Authorized User Menu',
            serviceCars: 'Service Vehicles Tracking',
            createServiceCarsDaily: 'Create Daily Tracking List',
            companyCarTracking: 'Company Car Tracking',
            visitReservation: 'Visit Reservation',
            freightLoadingReservation: 'Freight Shipment Reservation',
            freightArrivalReservation: 'Material Acceptance Reservation',
            myUserProcedures: 'My User Procedures',
            companyCarTracing: 'Company Car Tracking',
            freightProcedures: 'Freight Vehicle Procedures',
            cardlessPersonnelProcedures: 'Cardless Personnel Procedures',
            cardlessPersonnelCreate: 'Add Cardless Personnel',
            visitProcedures: 'Visit Procedures',
            serviceCarsProcedures: 'Service Vehicles Procedures',
            companyCarsProcedures: 'Company Car Procedures',
            reservationApproval: 'Reservation Approval',
            companyCarEditTitle: 'Edit Vehicle Tracking',
            weeklyVisitTracking: 'Weekly Visit Tracking',
            weeklyVisits: 'Weekly Visits',
            operationSettings: 'Operation Settings',

            dailyVisitTracking: 'Daily Visit Tracking',
            dailyVisits: 'Daily Visits',

            // COMPANY CARS TRACKING PAGE
            companyCarTrackingTitle: 'Company Car Tracking',
            operation: 'Operation',
            details: 'Detail',
            trackingDate: 'Date',
            fullName: 'Full Name',
            cardNumber: 'Card Number',

            firmVisits: 'Company Visits',
            phone: 'Phone Number',
            description: 'Description',
            create: 'Create',
            time: 'Time',
            companyName: 'Company Name',
            companyEmail: 'Company Email',
            commonEmail: 'Email',
            email: 'Email Address',
            commonUserRole: 'User Role',
            companyWorkFor: 'Company Worked For',

            //COMMON !! TEXT FOR BUTTONS, COLUMNS & INFO TEXT
            commonEdit: 'Edit',
            commonDelete: 'Delete',
            commonEditUser: 'Edit User',
            commonEditRes: 'Edit Reservation',
            commonDescription: 'Description',
            commonSaveButton: 'Save',
            commonCancelButton: 'Cancel',
            commonRemoveButton: 'Remove',
            commonCreateButton: 'Create',
            commonAddButton: 'Add',
            commonDate: 'Date',
            commonEntryDate: 'Entry Date',
            commonExitDate: 'Exit Date',

            commonWhoToMeet: 'Person to Visit',
            commonWhosMeeting: 'Person Visiting',
            commonOkButton: 'OK',
            commonHideButton: 'Hide',
            commonDecisionRes: 'Approve/Cancel Reservation',
            commonShowDescription: 'Show Description',
            commonAccept: 'Approve',
            commonReject: 'Reject',

            //Login Page
            logo1: "Ware",
            logo2: "Manage",
            title: "Meet the Renault Ware Manage System.",
            text1: "With its next-generation user-friendly interface, you can make reservations for material acceptance-loading or personal visits. Meet Ware Manage.",
            emai: "Your email address",
            password: "Password",
            rememberMe: "Remember Me",
            loginAsGuest: "Continue as Guest",
            login: "Login",
            newPassword: "Reset Password",
            welcomeText: "Welcome to Ware Manage Appointment System",

            //NO PAGE 
            noPage1: "You do not have permission to view this page.",
            noPage2: "If you believe you should be able to view this page, please contact an authorized person.",

            // Register Page
            register: "Register",
            company: "Company",
            registrationSucceeded: "Registration Successful",
            successRegisterMessage: "Your registration has been successfully received. A notification email will be sent to your email address once your registration is approved.",
            registrationFailed: "Registration Failed",
            failedRegisterMessage: "Your registration could not be received. Please check the information you entered during registration.",
            errorPasswordLengthMessage: "Your password must be at least 6 characters long.",
            companySelectionNote: "If your company is not listed, please start typing the name of the company.",

            //User Menu Page
            unloadRes: "Material Acceptance Reservation",
            loadRes: "Freight Shipment Reservation",
            visitRes: "Visit Reservation",
            userOperations: "My User Operations",
            passwordsNotMatch: "Passwords Do Not Match",
            passwordLength: "Password Must Be At Least 6 Characters",

            //User Settings
            reservationEdit: "Edit/Delete Reservation",
            reservationApprove: "Approve Reservation",
            userSettings: "User Settings",
            createNewUser: "Create New User",
            createNewUserTitle: "Create New User",
            companyList: "Company List",
            editCompany: "Edit Company",

            createNewFirm: "Create New Company",

            // User Approval 
            userApproval: "Approve/Reject User",

            //VisitingOperations
            createVisit: "Create Visit",

            //Cardless Employee
            titleCardless: "Add Cardless Personnel Tracking",
            dateCardless: "Date",
            whoToVisit: "Person to Visit",
            securityEmployee: "Security Officer",
            visitInOut: "Record Visit Entry-Exit",
            editCardlessEmployee: "Edit Cardless Personnel",
            cardlessEmployees: "Cardless Personnel",

            //Analytics Page
            today: "Today's Visits",
            week: "This Week's Visits",
            firm: "Company's Visits",

            //Authorized User Reservation Page
            authInputField1: "Name Surname",
            phoneNumberLabel: "Phone Number",
            authInputField3: "Person to Meet",
            authInputField4: "Reason for Meeting",
            authInputField5: "Notes",
            authInputField6: "Day of Meeting",
            authInputField7: "Time of Meeting",
            plateLabel: "Plate",
            truckPlateLabel: "Trailer Plate",

            arrivalTimeLabel: "Arrival Time",
            exitTimeLabel: "Exit Time",

            // Guest User Reservation Page
            firstName: "First Name",
            firstName2: "Name",
            lastName: "Last Name",
            fullNameOfThePersonToMeet: "Full Name of the Person to Meet",
            passwordRepeat: "Repeat Password",
            enlightmentText: "I have read and agree to the Information Text",

            //Security Menu
            loadTruckOperations: "Freight Vehicle Operations",
            cardlessEmployeeOperations: "Cardless Personnel Operations",
            visitOperations: "Visit Operations",
            usersOperations: "User Operations",
            serviceCarsOperations: "Service Vehicle Tracking",
            companyCarsOperations: "Company Car Tracking",

            // Freight Handling Page
            bookANewFreightAppointment: "Book a New Freight Appointment",
            loadTruck: "Freight Shipment Operations",
            unloadTruck: "Material Receipt Operations",
            companyWhichBook: "Company Making the Appointment",
            meetingPoint: "Meeting Point",
            reservationDay: "Visit Day",
            gateNumber: "Gate Number",
            peron: "Platform",
            reservationTime: "Visit Time",
            freightVehicleEdit: "Edit Freight Vehicle",
            vehiclePlate: "Vehicle Plate",
            reservationDate: "Visit Date",
            freightVehicleLoad: "Freight Vehicle Shipment Recording",
            freightVehicleLoadTitle: "Freight Vehicle Shipment",
            towTruckPlate: "Tow Truck Plate",
            loadReservationNote: "The appointment you are making is for the first stop.",
            loadReservationWarning: "Vehicles not complying with ramp loading standards will not be accepted.",

            freightVehicleUnload: "Freight Vehicle Receipt Recording",
            freightVehicleUnloadTitle: "Freight Vehicle Receipt",
            loadReservationApproval: "Appointment Cancellation/Approval",
            freightCalendar: "Operation Calendar",
            freightHandlingMenu: "Freight Vehicle (Operation) Operations",
            assignGateAndRoute: "Assign Ramp and Route",
            route2: "Route",
            gateNumber2: "Ramp",
            plate: "Plate",
            noWaitingAppointments: "No pending appointments available.",

            rejectFreightAppointment: "Reject Appointment",
            rejectFreightAppointmentText: "Are you sure you want to reject the appointment?",
            reject: "Reject",
            approve: "Approve",

            visitCalendar: "Visit Calendar",
            workingHoursManagement: "Working Hours Settings",

            // Freight Calendar
            monday: "Monday",
            tuesday: "Tuesday",
            wednesday: "Wednesday",
            thursday: "Thursday",
            friday: "Friday",
            saturday: "Saturday",
            seeCalendar: "View Calendar",
            freightArea: "Operation Area",
            appointmentDetails: "Appointment Details",

            // Freight Areas
            1010: "1010",
            3020: "3020",
            MAIS_SEVKIYAT: "MAIS SHIPMENT",
            ANTREPO: "WAREHOUSE",
            GPKS: "Guaranteed Parts Acceptance Service",
            OTHER: "OTHER",

            // Sub Titles
            editingReservation: "Editing Reservation",
            editReservation: "Edit Reservation",

            // Create Firm Page
            createFirm: "Create Firm",
            createFirmForm: "Firm Creation Form",
            saveFirm: "Save Firm",

            // Create User Page
            createUser: "Create User",
            createUserForm: "User Creation Form",

            // Service Cars Tracking Page
            addNewRoute: "Add New Route",
            route: "Route",

            removeOldRoute: "Remove Route",
            addCarTracking: "Add Car Tracking",
            routeName: "Route Name",
            entranceTime: "Entrance Time",
            exitTime: "Exit Time",
            entranceCarPlate: "Entrance Car Plate",
            exitCarPlate: "Exit Car Plate",
            addNewCarTracking: "Add Car Tracking",
            serviceCarAdd: "Add Service Car",
            morningEntryTime: "Morning Entry Time",
            eveningExitTime: "Evening Exit Time",
            eveningEntryTime: "Evening Entry Time",

            // Buttons
            addRoute: "Add Route",
            deleteRoute: "Remove Route",
            addTrack: "Add Car Tracking",

            // Reservation Edit Page
            reservationList: "Reservation List",
            timeErrorMessage: "Please check the entered times.",

            // Company Car Arrival
            carArrivalTitle: "Add Car Tracking",
            carArrivalDate: "Planned Date",
            carArrivalDateIn: "Planned Entry",
            carArrivalDateOut: "Planned Exit",
            carArrivalTableTitle: "Company Car Tracking",
            companyCarAdd: "Add Company Car",

            // NoPage
            missingPage: "Page Not Found.",

            // Reporting Menu
            visitAppointments: "Visit (Individual) Appointments",
            personToMeet: "Person to Meet",
            appointmentWith: "Appointment With",
            freightAppointments: "Freight (Operation) Appointments",
            visitorFullName: "Visitor",
            // other: "Other",

            // Daily Visit Tracking
            dailyVisitTitle: "Visit Arrival-Exit Recording",
            dailyTracking: "Daily Visit Tracking",
            visitInOutDaily: "Visit Arrival-Exit",

            // Appointments Page
            appointments: "Appointments",
            appointmentDate: "Appointment Date",
            appointmentTime: "Appointment Time",
            appointmentType: "Appointment Type",
            appointmentStatus: "Appointment Status",
            appointmentReason: "Meeting Reason",
            estimatedExitTime: "Estimated Exit Time",
            estimatedEntryTime: "Estimated Entry Time",
            arrivalTime: "Arrival Time",
            actualExitTime: "Actual Exit Time",

            // Appointment Types
            VISIT: "VISIT",
            OPERATION: "OPERATION",
            OTHER: "OTHER",

            // Appointment Statuses
            PENDING: "PENDING",
            APPROVED: "APPROVED",
            IN_PROGRESS: "IN PROGRESS",
            REJECTED: "REJECTED",
            CANCELLED: "CANCELLED",
            COMPLETED: "COMPLETED",
            PARTNER_COMPANY_PENDING: "PARTNER COMPANY PENDING",
            PARTNER_COMPANY_APPROVED: "PARTNER COMPANY APPROVED",

            // Roles
            SUPER_ADMIN: "SUPER ADMIN",
            AUTHORIZED_USER: "AUTHORIZED USER",
            PARTNER_COMPANY_AUTHORIZED_USER: "PARTNER COMPANY AUTHORIZED USER",
            OPERATION_RESPONSIBLE: "OPERATION RESPONSIBLE",
            SECURITY_GUARD: "SECURITY GUARD",
            STANDARD_USER: "STANDARD USER",
            GUEST: "GUEST",

            // Guest Reservation
            guestReservation: "Guest Reservation",

            // Pop-up Messages
            errorMessageTitle: "Your record could not be taken.",
            errorMessage: "Please ensure all fields are filled.",
            errorPasswordMessage: "Password and Repeat Password do not match.",
            deleteTitle: "Are you sure you want to delete the record?",
            deleteText: "Proceed to delete the record",
            // Snackbar Messages
            successMessage: "Your record has been successfully received.",
            successMessageForDelete: "Your record has been successfully deleted.",
            successAppointmentUpdate: "The appointment has been successfully updated.",

            // Forgot Password Page
            send: "Send",
            resetPassword: "Reset Password",
            'popup.invalidToken.title': 'Invalid Link',
            'popup.invalidToken.text': 'Your password reset link is invalid. Please request a password reset again.',
            'popup.invalidToken.button': 'Okay',

            // Side Bar
            mainMenu: "Main Menu",
            userManagement: "User Management",
            visitingOperations: "Visiting Operations",
            freightOperations: "Freight (Operation) Operations",
            logout: "Logout",

            // Verbs
            edit: "Edit",
            delete: "Delete",

            viewAppointments: "View My Appointments",
            appointmentBoard: "Appointment Board",
            operationResponsibleList: "Operation Responsible List",
            operationResponsible: "Operation Responsible",
            addOperationResponsible: "Add Operation Responsible",

            // Freight Download
            downloadAllCsv: "Download CSV",
            'company.name': "Company Name",
            // 'appointmentDate',
            // 'appointmentTime',

            // Freight Fields
            'freight.freightArea': "Operation Area",
            'freight.plate': "Vehicle Plate",
            'freight.trailerNumber': "Trailer Plate",

            // Created By
            'createdBy.email': "Appointment Created By Email",

            // Entrance and Exit Times
            // 'entranceTime': "Entrance Time",
            // 'actualExitTime': "Exit Time",

            // Working Hours Management Page
            startTime: "Start Time",
            endTime: "End Time",
            slotDuration: "Appointment Duration (Minutes)",
            slotDurationFor3020Sevkiyat: "Appointment Duration (3020 Shipment)",
            breaks: "Breaks",
            addBreak: "Add Break",
            breakStart: "Break Start",
            breakEnd: "Break End",
            extraHours: "Overtime Hours",
            enableExtraHours: "Enable Overtime Hours",
            disableExtraHours: "Disable Overtime Hours",
            saveConfiguration: "Save Settings",
            saveConfigurationText: "The changes you made will affect the appointment times. Do you want to proceed?"
        }
    }

}